<template>
  <div class="alarmStatus">
    <div class="contant">
      <div class="toptit">告警信息配置</div>

      <div class="inputbox">
        <div class="title">门店订单金额30天内低于多少告警</div>
        <div class="insetbox">
          <input type="text" v-model="ordermath" placeholder="请输入金额">
        </div>
      </div>
<!--      <div class="switchbox">-->
<!--        <div class="text">所选设备旗下的手炳产生“按键失灵，摇杆漂移，手柄离线"异常进行告警</div>-->
<!--        <van-switch v-model="joyconswitch" size="22px" />-->
<!--      </div>-->
      <div class="switchbox">
        <div class="text">设备离线1天告警</div>
        <van-switch v-model="outlineoneday" size="22px" />
      </div>
      <div class="switchbox lastswitch">
        <div class="text">设备网络状态为“低”告警</div>
        <van-switch v-model="netlowstatus" size="22px" />
      </div>

      <div class="toptit">微信服务通知配置</div>
      <div class="wechatbox">
        <div class="switchbox">
          <div class="text">是否开启微信服务通知</div>
          <van-switch v-model="wechartswitch" size="22px" />
        </div>

        <div class="item">
          <div class="title">提醒区间</div>
          <div class="selectbox" @click="showrangepup">
            <span>{{ remindrange }}</span><img src="@/assets/img/arrow_down.png" alt="">
          </div>
        </div>

        <div class="item">
          <div class="title">推送周期</div>
          <div class="checkcont">
            <van-radio-group v-model="valchecked" direction="horizontal" @change="checkboxchange"
              style="height:100%;overflow:hidden">
              <van-radio name="1">随时推送</van-radio>
              <van-radio name="2">自定义</van-radio>
            </van-radio-group>
          </div>
        </div>

        <div class="item" v-if="valchecked == 2">
          <div class="title">每隔</div>
          <div class="insetbox">
            <input type="text" v-model="hourrange" placeholder="请输入整数">
          </div>
          <div class="text">小时推送一次</div>
        </div>

        <div class="item">
          <div class="title">推送给谁</div>
          <div class="selectbox" @click="showpushpup">
            <span>{{ pushto ? pushto : '代理/签约BD/运维BD' }}</span><img src="@/assets/img/arrow_down.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="btns">
      <button type="button" @click="backclick">返回</button>
      <button type="button" @click="subclick">提交</button>
    </div>

    <van-popup v-model:show="pushpup" position="bottom" round>
      <div class="mutychois">

        <div class="puptit">
          <button @click="pushcancer">取消</button>
          <div class="text">推送给谁(可多选)</div>
          <button @click="pushok">确定</button>
        </div>

        <van-checkbox v-model="isCheckAll" :indeterminate="isIndeterminate" @change="checkAllChange">
          {{ firstitem }}
        </van-checkbox>

        <van-checkbox-group v-model="checkedResult" @change="checkedResultChange">
          <van-checkbox v-for="item in choiselist" :key="item" :name="item">{{ item.title }}</van-checkbox>
        </van-checkbox-group>

      </div>
    </van-popup>

    <van-popup v-model:show="rangepup" position="bottom" round>
      <van-picker title="提醒区间" :columns="columns" @confirm="onConfirm" @cancel="onCancel" />
    </van-popup>

    <van-popup v-model:show="starttimepup" position="bottom" round>
      <van-picker v-model="currentstartTime" :columns="starthourlist" title="选择开始时间" @confirm="setstarttime"
        @cancel="startcancer" />
    </van-popup>
    <van-popup v-model:show="endtimepup" position="bottom" round>
      <van-picker v-model="currentendTime" :columns="endhourlist" title="选择结束时间" @confirm="setendtime"
        @cancel="endcancer" />
    </van-popup>

  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"
import mitts from "@/bus";
import API from "@/axios";
import { Toast } from "vant";
import { useRouter } from "vue-router";

const router = useRouter();

// 订单金额少于
const ordermath = ref("")

// 手炳失灵报警
const joyconswitch = ref(true)
// 设备离线1天告警
const outlineoneday = ref(true)
// 设备网络状态为“低”告警
const netlowstatus = ref(true)

// 微信通知
const wechartswitch = ref(true)
// 提醒区间
const remindrange = ref("全天")
// 推送周期
const valchecked = ref("1")
// 每隔 xx 小时推送一次
const hourrange = ref("0")
// 推送给谁
const firstitem = ref("")
const pushto = ref("")
const notice_role = ref("")

const pushpup = ref(false)
const choiselist = ref([])

const isCheckAll = ref(false);
const checkedResult = ref([]);
const isIndeterminate = ref(true);

const checkAllChange = (val) => {
  checkedResult.value = val ? choiselist.value : []
  isIndeterminate.value = false
}

const checkedResultChange = (value) => {
  const checkedCount = value.length
  isCheckAll.value = checkedCount === choiselist.value.length
  isIndeterminate.value = checkedCount > 0 && checkedCount < choiselist.value.length

  // console.log(checkedResult.value)
}

const pushcancer = () => {
  pushpup.value = false
}

const showpushpup = () => {
  pushpup.value = true
}

const pushok = () => {
  pushto.value = checkedResult.value.map((obj) => { return obj.title }).join("/")
  notice_role.value = checkedResult.value.map((obj) => { return obj.val }).join(",")
  pushpup.value = false
}

const checkboxchange = (e) => {
  console.log(e)
}

// 提醒区间
const rangepup = ref(false)

const columns = ref([
  { text: '全天', value: 'allday' },
  { text: '自定义', value: 'diy' },
]);

const showrangepup = () => {
  rangepup.value = true
}

const onConfirm = (e) => {
  if (e.text == "自定义") {
    starttimepup.value = true
  } else {
    remindrange.value = e.text
  }

  rangepup.value = false
};

const onCancel = () => {
  rangepup.value = false
};

const starthourlist = ref(["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"])

const endhourlist = ref(["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"])

// 开始时间
const starttimepup = ref(false);
const currentstartTime = ref("");

const setstarttime = (e) => {
  currentstartTime.value = e
  starttimepup.value = false
  endtimepup.value = true
}

const startcancer = () => {
  starttimepup.value = false
}

// 结束时间
const endtimepup = ref(false);
const currentendTime = ref("");

const setendtime = (e) => {
  currentendTime.value = e
  endtimepup.value = false
  remindrange.value = currentstartTime.value + "~" + currentendTime.value
}

const endcancer = () => {
  endtimepup.value = false
  starttimepup.value = true
}

const backclick = () => {
  router.push({
    path: "/event/event",
    query: { url: encodeURIComponent("/web/alarmList") },
  });
}

const subclick = () => {
  // 提交按钮
  API({
    url: "api/alarmConfSubmit",
    method: "post",
    data: {
      store_sales: ordermath.value,
      device_offline: outlineoneday.value ? 1 : 0,
      handle_abnormal: joyconswitch.value ? 1 : 0,
      network_low: netlowstatus.value ? 1 : 0,
      notice_open: wechartswitch.value ? 1 : 0,
      notice_time: remindrange.value == "全天" ? "" : remindrange.value,
      notice_hour: hourrange.value,
      notice_role: notice_role.value,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      // console.log(res.data.data)
      router.push({
        path: "/event/event",
        query: { url: encodeURIComponent(res.data.data.url) },
      });

    } else {
      Toast.fail(res.data.msg);
    }
  });
}

function getInfo() {
  API({
    url: "api/alarmConfPage",
    method: "post",
    data: {},
  }).then((res) => {
    if (res.data.status == 200) {
      // console.log(res.data.data)

      let infodata = res.data.data

      ordermath.value = infodata.config.store_sales
      joyconswitch.value = infodata.config.handle_abnormal == 1 ? true : false
      outlineoneday.value = infodata.config.device_offline == 1 ? true : false
      netlowstatus.value = infodata.config.network_low == 1 ? true : false
      wechartswitch.value = infodata.config.notice_open == 1 ? true : false
      remindrange.value = infodata.config.notice_time == "" ? "全天" : infodata.config.notice_time
      hourrange.value = infodata.config.notice_hour == "" ? "0" : infodata.config.notice_hour

      firstitem.value = infodata.config.notice_role[0].title
      choiselist.value = infodata.config.notice_role.filter((element, index) => index > 0)

      let checkarry = []

      for (let i = 0; i < choiselist.value.length; i++) {
        if (choiselist.value[i].checked == 1) {
          checkarry.push(choiselist.value[i])
        }
      }

      if (choiselist.value.length == checkarry.length) {
        isCheckAll.value = true
        pushto.value = infodata.config.notice_role[0].title
        notice_role.value = 0
      } else {
        isCheckAll.value = false
        checkedResult.value = checkarry
        pushto.value = checkarry.map((obj) => { return obj.title }).join("/")
        notice_role.value = checkarry.map((obj) => { return obj.val }).join(",")
      }

      // console.log(notice_role.value)

    } else {
      Toast.fail(res.data.msg);
    }
  });
}

onMounted(() => {
  getInfo()
  setTimeout(() => {
    mitts.emit("closeLoading");
  }, 500);
});

</script>

<style lang="less" scoped>
.alarmStatus {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.contant {
  flex-grow: 1;
  height: 10px;
  box-sizing: border-box;
  background: #fff;
  margin: 20px;
  overflow-y: auto;
  padding-top: 10px;
}

.toptit {
  font-size: 32px;
  font-family: "Bold";
  margin: 0 20px;
  padding: 40px 0 20px 0;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 30px;
}

.inputbox {
  background: #fff;
  border-radius: 20x;
  padding: 20px 20px 40px 20px;
  box-sizing: border-box;

  .title {
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 28px;
  }

  .insetbox {
    width: 100%;
    height: 60px;
    border: 2px solid #dfdfdf;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-radius: 10px;
    margin-right: 15px;
    box-sizing: border-box;

    input {
      height: 50px;
      flex: 1;
      width: 100%;
      display: block;
      outline: none;
      font-size: 28px;
    }

    span {
      flex: 1;
      min-width: 0;
      margin-right: 20px;
    }

    img {
      width: 20px;
      display: block;
    }
  }

}

.switchbox {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;

  .text {
    flex: 1;
    margin-right: 30px;
    height: 80px;
    display: flex;
    align-items: center;
  }
}

.lastswitch {
  margin-bottom: 0;
}

.wechatbox {
  background: #f0f0f0;
  border-radius: 20px;
  padding: 20px;
  margin: 0 20px;
  box-sizing: border-box;
  border: 1px solid #dfdfdf;
  margin-bottom: 30px;

  .switchbox {
    padding: 0;
    margin-bottom: 20px;

    .text {
      flex: 1;
      margin-right: 30px;
      height: auto;
    }
  }

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .title {
      font-size: 28px;
      line-height: 28px;
      margin-right: 15px;
      width: 113px;
      text-align: right;
    }

    .insetbox {
      width: 220px;
      height: 60px;
      border: 2px solid #dfdfdf;
      display: flex;
      align-items: center;
      padding: 0 15px;
      border-radius: 10px;
      margin-right: 15px;
      background: #fff;

      input {
        height: 50px;
        flex: 1;
        width: 100%;
        display: block;
        outline: none;
        font-size: 28px;
      }

      span {
        flex: 1;
        min-width: 0;
        margin-right: 20px;
      }

      img {
        width: 20px;
        display: block;
      }
    }

    .selectbox {
      flex: 1;
      height: 60px;
      border: 2px solid #dfdfdf;
      display: flex;
      align-items: center;
      padding: 0 15px;
      border-radius: 10px;
      box-sizing: border-box;
      background: #fff;
      min-width: 0;

      span {
        flex: 1;
        min-width: 0;
        margin-right: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      img {
        width: 20px;
        display: block;
      }
    }

    .checkcont {
      height: 60px;

      .van-radio__icon .van-icon {
        width: 50px;
        height: 50px;
      }
    }

    .text {
      font-size: 28px;
      line-height: 28px;
    }
  }

  .item:last-of-type {
    margin: 0;
  }
}

.btns {
  display: flex;
  padding: 20px;
  box-sizing: border-box;

  button {
    background: none;
    font-size: 28px;
    height: 66px;
    line-height: 66px;
    border-radius: 10px;
    margin-right: 20px;
    flex: 1;
    border: 3px solid @theme-secondary-color;
    color: @theme-secondary-color;
  }

  button:last-of-type {
    border: 3px solid @theme-secondary-color;
    background: @theme-secondary-color;
    color: @font-white-color;
    margin-right: 0;
    flex: 2;
  }
}

.mutychois {
  width: 100%;
  box-sizing: border-box;

  .puptit {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .text {
      font-size: 32px;
      color: #999;
      flex: 1;
      min-width: 0;
      text-align: center;
    }

    button {
      font-size: 28px;
      color: #666;
      background: none;
    }

    button:last-of-type {
      color: #486ffb;
    }
  }
}
</style>